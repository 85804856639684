.inputError {
  outline: 2px solid transparent;
  outline-offset: 2px;
  border: 1px solid rgba(229, 0, 0, 1) !important;
}

.error-text {
  color: #e50000;
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
}


body::-webkit-scrollbar {
  width: .2em;
  background-color: #202020;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: #009797;
}

