button{
    right: 70px;
    
}

input{
    color:black;


}

input:focus{
    outline: none;
    border:none;
}